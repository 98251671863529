import React, { useState, useEffect } from "react";
import axios from "axios";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './../assets/css/category-carousle.css';
/*import './../assets/css/custom.css';*/

import { Link } from 'react-router-dom';
import { render } from 'react-dom';
import { Heart, HeartOutline, Star, StarOutline, Location, MenuOutline, CloseCircleOutline, FaTwitter } from 'react-ionicons';
import Loginmodal from './Loginmodal';
import TopSearch from '../components/TopSearch';

//import FullPageLoader from './FullPageLoader/FullPageLoader';
//import useFullPageLoader from '../hooks/useFullPageLoader';
//import styled from 'styled-components';

const inspired = {
	margin: 0,
	loop: true,
	responsiveClass: true,
	nav: false,
	dots: false,
	autoplay: true,
	navText: false,
	autoplayTimeout: 5000,
	smartSpeed: 500,
	autoplayHoverPause: true,
	responsive: {
		0: {
			items: 1,
		},
		991: {
			items: 6,
		},
		767: {
			items: 4,
		},
		575: {
			items: 3,
		},
		320: {
			items: 2,
		}
	},
};

function Home(props) {
	//console.log(props.language);

	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	//console.log(props.userContent);
	//console.log(props.chgUserContent);
	//const loadingelement = document.querySelector('loadingelement');
	//const [loader, showloader, hideloader] = useFullPageLoader();

	let countrydata = (props.countryId != '') ? props.countryId : null;
	// console.log("home_page " + props.countryId);
	//let [countryById, setCountryById] = useState(props.countryId);
	//console.log(props.countryById);
	let statename = (props.statename != '') ? props.statename : null;
	//console.log("home:-"+statename);
	let [userContent, setUserContent] = useState(props.userContent);

	//console.log(userContent);
	useEffect(() => {
		props.chgUserContent(userContent);
	}, [userContent]);

	// get country id
	/*let [countryById, setCountryId] = useState(props.countryId);
	//console.log(countryById);
	useEffect(()=> {
		setCountryId(props.countryId);
	}, [props.countryId]);
	// console.log("country-id:- "+countryById);*/
	//countrydata  = countryById;
	// item search by country and 

	const [loginPopupShow, setLoginPopupShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);
	let loggedInUserId = localStorage.getItem("loggedin_user_id");

	let languageContent = props.languageContent;
	const [categoryList, setCategoryList] = useState([]);
	useEffect(() => {
		//showloader();
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/categories/${props.language}`).then((response) => {
				//hideloader();
				// console.log(response.data);
				setCategoryList(response.data);
			});
		});
	}, [props.language]);

	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);
	}


	//---- item list data---------
	let lkclass = '';
	let favClass = '';

	const [likeclass, setLkClass] = useState([]);
	const [favsClass, setFavClass] = useState([]);
	const [totalLike, setTotalLike] = useState([]);

	const [allItemsList, setAllItemsList] = useState([]);
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	//console.log("COUNTRY--IDSSS:-"+countrydata);

	useEffect(() => {
		//showloader();
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/all-items-list/${loggedInUserId}/${props.language}/${countrydata}/${statename}`).then((response) => {
				//hideloader();
				//alert(response.data);
				setAllItemsList(response.data.arrItemDetails);
				setItemsLoad(response.data.items_load_more);
				setVisible(response.data.items_load_more);
				//document.body.classList.remove('disable_load_content');
			});
		});
	}, [countrydata, props.language, statename]);


	// get item by country

	//  end

	//console.log(allItemsList);
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	const likeMeClick = value => {
	// const likeMeClick = value => () => {
		//console.log(value);
		//alert(value);
		let id = value;
		//alert(countrydata);
		//alert(id);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/home-item-like/${loggedInUserId}/${id}/${countrydata}`).then((response) => {
				// console.log(response.data);
				//setLkClass(response.data.likeclass);
				//console.log("liks: "+likeclass);
				setAllItemsList(response.data);
			});
		});
	};

	const favouriteMeClick = value => {
	// const favouriteMeClick = value => () => {
		//console.log(value);
		let id = value;
		//alert(id);alert(loggedInUserId);alert(countrydata);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/home-item-favourite/${loggedInUserId}/${id}/${countrydata}`).then((response) => {
				// console.log(response.data);
				//setFavClass(response.data.favouriteClass);
				//console.log("fav: "+favsClass);
				setAllItemsList(response.data);
			});
		});
	};
	//const likeStyle = {color: 'red', fontSize: '50px'}
	//const favClassUnChk = {color: 'red', background: 'white',fontSize: "1.5em" }

	const likeClassStyle = { padding: '4px', background: '#fff', borderRadius: '50%', marginRight: '10px', fill: 'orange', width: '28px', height: '28px' }
	const unLikeClassStyle = { color: 'orange', padding: '4px', background: '#fff', borderRadius: '50%', marginRight: '10px', width: '28px', height: '28px' }
	const favourClassStyle = { padding: '4px', background: '#fff', borderRadius: '50%', marginRight: '10px', fill: 'orange', width: '28px', height: '28px' }
	const unFavourClassStyle = { color: 'orange', padding: '4px', background: '#fff', borderRadius: '50%', marginRight: '10px', width: '28px', height: '28px' }
	//----------------------------
	//console.log("home#page-today:-"+statename);
	/*useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-state-wise-item/${statename}`).then((response)=>{		 console.log(response);				            
				//setAllItemsList(response);
				setAllItemsList(response.data.arrItemDetails);
				setItemsLoad(response.data.items_load_more);
				setVisible(response.data.items_load_more);
				//document.body.classList.remove('disable_load_content');
			});
		 });
	}, []);*/

	/*if(statename!='All')
	{
		//alert(statename);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-state-wise-item/${statename}`).then((response)=>{	 	 console.log(response);				            
				//setAllItemsList(response);
				setAllItemsList(response.data.arrItemDetails);
				setItemsLoad(response.data.items_load_more);
				setVisible(response.data.items_load_more);
				//document.body.classList.remove('disable_load_content');
			});
		 });
	}*/
	//----------------------------
	return <>
		<section>
			<div style={{ background: "url('./images/header-banner.jpg')" }} className="banner">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-8 col-sm-12 col-12 offset-md-4 offset-lg-4 text-center">
							
							<p>Snapsells.com is a vibrant marketplace where individuals offer standout services and products, connecting sellers and buyers effortlessly. Dive in for seamless buying and selling experiences worldwide.</p>
							
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="browsing-history text-center">
					<h2 className="section-title">Inspired By Your Browsing History </h2>
					<OwlCarousel items={3} className="owl-theme" loop nav autoplay={true} autoplayTimeout={5000} smartSpeed={500} autoplayHoverPause={true} {...inspired}>
						{categoryList.map((val) => {
							return <>
								<Link to={`/category/${val.slug}`} className="item slick-slide d" key={val.id}>
									<div className="slide-back d-flex">
										<img className="icon-svg" style={{width:"80px", height:"80px"}} src={process.env.REACT_APP_SERVER_API_URL + `images/category/${val.id}/${val.category_icon}`} alt={val.categoryname} />
									</div>
									<p>{val.categoryname}</p>
								</Link>
							</>;
						})}
					</OwlCarousel>
				</div>
			</div>
		</section>



		<section>
			<div className="mobileSearch mobileView">
				<TopSearch countryId={props.countryId}/>
			</div>
			<div className="products-section" id="home-product">
				<div className="container productContainer">
					<div className="row productRow">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column homeLeft" style={{ display: isOpen ? 'inline' : 'none' }}>
							<div className="productClose"><CloseCircleOutline onClick={onClickMenu} /></div>
							<h2 className="section-title mb-4">{languageContent.select_a_category}</h2>
							<ul>
								{categoryList.map((val) => {
									return <>
										<li key={val.id}>
											<Link to={`/category/${val.slug}`} language={props.language}>
												{/*<img className="icon-svg" src={process.env.REACT_APP_SERVER_API_URL + `images/category/${val.id}/${val.category_icon}`} alt={val.categoryname}/>*/}
												{val.categoryname}</Link>
										</li>
									</>;
								})}
							</ul>
						</div>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 products-column homeRight">
							<div className="mobileCat"><MenuOutline onClick={onClickMenu} /></div>
							<h2 className="section-title mb-4 ml-1">Fresh Recommendations</h2>
							<div className="grid-container row">
								{localStorage.getItem("loggedin_user_id") === null ? (
									<Loginmodal languageContent={languageContent} loginPopupShow={loginPopupShow} registerShow={registerShow} onLoginPopupCloseModal={() => setLoginPopupShow(false)} onRegisterPopupCloseModal={() => setRegisterShow(false)} chgUserContent={userContent => setUserContent(userContent)} />
								) : (
									null
								)}
								{allItemsList.length ? (
									<>
										{allItemsList.slice(0, visible).map((val) => {
											//{allItemsList.map((val) => {
											return <>
												<div className="col-md-4 wid_50" key={val.id}>
													<div className="product-card">
														<Link to={"/item/" + `${val.slug}-id-${val.id}`} className="HomeItemImage">
															<div className="product-image sponsoredBadge">
																<img src={process.env.REACT_APP_SERVER_API_URL + `images/items/${val.id}/${val.image}`} alt={val.image} />
																{val.is_sponsered == 1 ? (
																	<span className="sponsored_item">Sponsored</span>) : ''
																}
																<span className="new_price_label">{val.currency_symbol} {val.price}</span>
															</div>
														</Link>
														<div className="product-info">
															{localStorage.getItem("loggedin_user_id") === null ? (
																<div className="like-fav d-flex">
																	<Link to value={val.id} onClick={() => setLoginPopupShow(true)} className=""><HeartOutline style={{ color: '#3498db', fontSize: '20px' }} /></Link>
																	<Link to onClick={() => setLoginPopupShow(true)} className=""><StarOutline color={'#3498db'} className="likeFavText" /></Link>
																</div>
															) : (
																<div className="like-fav d-flex">
																	<Link to value={val.id} onClick={() => likeMeClick(`${val.id}`)} className="">
																		{val.likeclass == 1 ? <Heart color={'#3498db'}  /> : <HeartOutline color={'#3498db'} />}
																	</Link>
																	<Link to value={val.id} onClick={() => favouriteMeClick(`${val.id}`)} className="" data-id="296">
																		{val.favouriteclass == 1 ? <Star color={'#3498db'} className="likeFavText"  /> : <StarOutline color={'#3498db'}/>}
																	</Link>
																</div>
															)}
															<p className="product-price">{val.currency_symbol} {val.price}</p>
															<h3 className="product-title">
																<Link to={"/item/" + `${val.slug}-id-${val.id}`}>
																	{val.itemname}</Link>
															</h3>
															<ul>
																<li>
																	{val.categoryname}
																</li>
															</ul>
															<p className="location d-flex">
																<Location />
																<span className="location-text-limit"> {val.location} </span>
															</p>
															<div className="d-flex justify-content-between like_and_fav">
															{localStorage.getItem("loggedin_user_id") === null ? (
																<>
																<div>
																	<div className="w-100">
																		<Link to value={val.id} onClick={() => setLoginPopupShow(true)} className=""><HeartOutline style={{ color: '#3498db', fontSize: '20px' }} /></Link>&nbsp;<span>Likes</span>

																	</div>
																</div>
																<div>
																	<Link to onClick={() => setLoginPopupShow(true)} className=""><StarOutline color={'#3498db'} /></Link><span className="likeFavText">&nbsp;Add to favourites</span>
																</div>
																</>
															) : (
																<>
																<div>
																	<div className="w-100">
																		<Link to value={val.id} onClick={() => likeMeClick(`${val.id}`)} className="">
																			{val.likeclass == 1 ? <Heart color={'#3498db'} /> : <HeartOutline color={'#3498db'} />}<span className="likeFavText">likes</span>
																		</Link>
																	</div>
																</div>
																<div>
																	<Link to value={val.id} onClick={() => favouriteMeClick(`${val.id}`)} className="" data-id="296">
																	{val.favouriteclass == 1 ? <Star color={'#3498db'} className="likeFavText" /> : <StarOutline color={'#3498db'} />}<span className="likeFavText">add to Favourite</span>
																	</Link>
																</div>
																</>
															)}	
															</div>
														</div>

													</div>
												</div>
											</>;
										})}
										{visible < allItemsList.length && (
											<div className="col-md-12 mt-3 mb-3 text-center">
												<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
											</div>
										)}
									</>
								) : (
									<div className="col-md-12">
										<div className="noRecord mb-5">
											<h6>No Record Found</h6>
											<Link to="/add-item">Add Item</Link>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{ /*<section>
	<div className="dApp">
		<div className="dwldApp">
			<div className="dwImgPart">
				<img className="icon-svg" src="images/download_app.png" alt="icon" />
			</div>
			<div className="dwText">
				<h6>Download The Snapsells App</h6>
				<p>Buy, sell and find just about anything using the app on your mobile.</p>
				<div className="twoStore">
					<Link to="#"><img src="images/play_store.png" alt="Google Play" title="Google Play" /></Link>
					<Link to="#"><img src="images/app_store.png" alt="Apple Store" title="Apple Store" /></Link>
				</div>
			</div>
		</div>
	</div>
</section> */ }

		{/*<FullPageLoader/>*/}


	</>;
}

export default Home;
