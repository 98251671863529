import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/default.css";
import "./assets/css/custom.css";
import "./assets/css/select2.min.css";

import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import GoToTop from "./GoToTop";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Link,
  useParams,
} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  Notifications,
  Chatbox,
  Camera,
  TrashOutline,
  MenuOutline,
  AlertCircle,
  ArrowForward,
  PhonePortraitSharp,
} from "react-ionicons";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Home from "./components/Home";
import Cms from "./components/cms/Cms";
import Test from "./components/Test";
import ContactUs from "./components/leftbar/contact-us";
//import AboutUs from './components/leftbar/about-us';
import Tophead from "./components/Tophead";
import TopSearch from "./components/TopSearch";
import Resetpassword from "./components/Resetpassword";
import Verifyuser from "./components/Verifyuser";
import Appliances from "./components/Appliances";
import Categorylist from "./components/category/Category-list";
import Categorydetails from "./components/category/Category-details";
import Userdashboard from "./components/leftbar/user-dashboard";
import Myprofile from "./components/leftbar/My-profile";
import Additem from "./components/leftbar/Add-item";
import Addbulletin from "./components/leftbar/Add-bulletin";
import Mybulletin from "./components/leftbar/My-bulletin";
import Bulletin from "./components/leftbar/Bulletin";
import Edititem from "./components/leftbar/Edit-item";
import Editbulletin from "./components/leftbar/Edit-bulletin";
import Notification from "./components/leftbar/Notification";
import Help from "./components/leftbar/Help";
import Sponsoredplans from "./components/leftbar/Sponsored-plans";
import Customersupports from "./components/leftbar/Customer-support"; //
import Review from "./components/leftbar/Review";
import Reviewitem from "./components/leftbar/Reviewitem";
import BlockUsers from "./components/leftbar/Block-users";
import Emergencycontact from "./components/leftbar/Emergency-contact";
import Favouriteitem from "./components/leftbar/Favourite-item";
import Likeitem from "./components/leftbar/Like-item";
import Reportitem from "./components/leftbar/Report-item";
import ArrangeMeeting from "./components/leftbar/Arrange-meeting";
import Myitem from "./components/leftbar/My-item";
import Myitemsold from "./components/leftbar/My-item-sold";
import Itemdetails from "./components/leftbar/Item-details";
import Itemdetailstest from "./components/leftbar/Item-details-test";
import Chat from "./components/leftbar/Chat";
import Userdetails from "./components/leftbar/User-profile-details";
import PaymentDetails from "./components/leftbar/PaymentDetails";
import VerifiedIcon from '@mui/icons-material/Verified';
import Loginmodal from "./components/Loginmodal";

import CategoryWiselist from "./components/category/Category-wise-list";
import AllNotification from "./components/leftbar/Notification";
import TermCondition from "./components/leftbar/Term-condition";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
  TwitterShareButton,
  TwitterIcon,
  generateShareIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import useGeoLocation from "./hooks/useGeoLocation";

import arrayShuffle from "array-shuffle";
import { Avatar, Badge, Divider, Grid, List, ListItem, Typography } from "@mui/material";

axios.defaults.baseURL = "https://snapsells.com/admin/public/"; // server
// axios.defaults.baseURL = "http://localhost:8000/"; // local

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;

function App() {
  //const navigate = useNavigate();
  //Server connection start
  /*window.Echo = new Echo({
		broadcaster: 'pusher',
		key: 'e755483e6df842e32d7b',
		cluster: 'ap2',
		wsHost: window.location.hostname,
		forceTLS: true,
		disableStats: true
	});*/

  // local connection
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "e755483e6df842e32d7b",
    cluster: "ap2",
    wsHost: window.location.hostname,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
  });
  //Server connection end
  //Language change start
  //-------------loaction get------------------------

  //------------ location end-------------------------
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "english"
  );
  if (!languageStoredInLocalStorage) {
    localStorage.setItem("language", language);
  }
  let [languageContent, setLanguageContent] = useState([]);
  useEffect(() => {
    //alert(language);
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/language/${language}`).then((response) => {
        setLanguageContent(response.data);
        //console.log(response.data);
      });
    });
  }, [language]);
  //console.log("lengu:--"+language);
  const { slug } = useParams();
  //console.log("app :-"+slug);
  //Language change end
  const [loginPopupShow, setLoginPopupShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [verifyAccount, verifyAccountShow] = useState(false);
  const [mobileVerify, mobileVerifyShow] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const onClickMenu = () => {
    setisOpen(!isOpen);
  };
  //loggedin user details if available start
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  //console.log(loggedInUserId);
  let [userContent, setUserContent] = useState([]);
  useEffect(() => {
    if (loggedInUserId != null) {
      axios.get(`/sanctum/csrf-cookie`).then((response) => {
        axios.get(`/api/user/${loggedInUserId}`).then((response) => {
          //console.log(response.data);
          setUserContent(response.data);
        });
      });
    }
  }, [loggedInUserId]);
  //console.log(userContent);
  //console.log("login user id:-"+loggedInUserId);
  let [countryName, setCountryName] = useState([]);
  useEffect(() => {
    //if(loggedInUserId!=null){
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/getcountry`).then((response) => {
        // console.log(response.data);
        setCountryName(response.data);
      });
    });
    //}
  }, []);

  //---- country name status=2 show in footer--------
  let [countryNameFooter, setCountryNameFooter] = useState([]);
  useEffect(() => {
    //if(loggedInUserId!=null){
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/getselectedcountry`).then((response) => {
        // console.log(response.data);
        setCountryNameFooter(response.data);
      });
    });
    //}
  }, []);
  //----------- country statefooter---------------------
  let [countryState, setCountryState] = useState([]);
  useEffect(() => {
    //if(loggedInUserId!=null){
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/getcountrystate`).then((response) => {
        // console.log(response.data);
        setCountryState(response.data);
      });
    });
    //}
  }, []);
  //console.log("country name-: "+countryName);
  //loggedin user details if available end
  //console.log(localStorage.getItem("loggedin_user_id"));

  //Logout Start
  const handleLogout = () => {
    /*let ItemId   = localStorage.getItem("view_item_id");
		let sellerId = localStorage.getItem("view_seller_id");
		//console.log("logout->"+loggedInUserId+' '+ItemId+' '+sellerId);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/item-view-by-buyer-delete/${loggedInUserId}/${ItemId}/${sellerId}`).then((response)=>{		
				// console.log(response.data);
			});
		});*/

    localStorage.clear();
    localStorage.setItem("language", language);
    window.location.pathname = "/";
  };
  //Logout End

  // ---- browse by category name------------
  let [categoryBrowseDetails, setCategoryBrowseDetails] = useState([]);
  //if(loggedInUserId===null){
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/categories-by-browse/${language}`).then((response) => {
        console.log(response.data, "");
        setCategoryBrowseDetails(response.data);
      });
    });
  }, [language]);

  console.log(categoryBrowseDetails, "categoryBrowseDetails");

  const createRows = (data, rows, columns) => {
    const itemsPerRow = Math.ceil(data.length / rows); // Number of items per row
    const rowsArray = [];

    for (let i = 0; i < rows; i++) {
      rowsArray.push(data.slice(i * itemsPerRow, (i + 1) * itemsPerRow));
    }

    return rowsArray;
  };

  const rows = createRows(categoryBrowseDetails, 25, 5);

  console.log(rows, "categoryBrowseDetails");

  let row = 5;
  let i = 0;
  let ulTag = "</ul><ul>";

  //----suffle the categories name----
  let [categoriesShuffled, setCategoriesShuffled] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/categories-by-browse-for-menu/${language}`)
        .then((response) => {
          // console.log(arrayShuffle(response.data));
          setCategoriesShuffled(arrayShuffle(response.data));
        });
    });
  }, [language]);
  // const categoriesShuffled = arrayShuffle(categoryBrowseDetails);
  // console.log(categoriesShuffled);
  //---------Notification----------------------------
  let [getNotification, setGetNotification] = useState([]);
  {
    /*useEffect(()=> {
			axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-notification/${loggedInUserId}`).then((response)=>{
				//console.log(response.data);
				setGetNotification(response.data);
			});
			});
		}, []);*/
  }
  //console.log("not data: "+getNotification);

  const [shownoti, setShowNoti] = useState(false);
  const delete_all_notifi = () => {
    //console.log("user:");
    //let UserId = value;
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/delete-all-notification/${loggedInUserId}`)
        .then((response) => {
          //console.log(response.data);
          setGetNotification(response.data);
          //this.mydropdown= this.mydropdown.bind(this);
          //const delete_all_notifi = () => setShowNoti(true);
          //setActive(!isActive);  };
        });
    });
  };
  //let [notiLength, setNotiLength] = useState([]);
  const show_notifi = (value) => () => {
    let UserId = value;

    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/get-notification/${UserId}/${language}`)
        .then((response) => {
          //console.log(response.data.arrNotification);
          setGetNotification(response.data.arrNotification);
          //setNotiLength(getNotification.length);
        });
    });
  };
  //console.log(getNotification);
  //console.log(getNotification.length);

  // get items by Selected Country
  let [countryId, setCountryId] = useState(localStorage.getItem("country_id"));
  //let [countryId, setCountryId] = useState(226);
  // setCountryId();
  const handleCountryChange = (e) => {
    //this.setState({selectedValue: e.target.value})
    var country_id = e.target.value;
    // setCountryId(country_id);
    localStorage.setItem("country_id", country_id);
    //alert(country_id);
  };
  // console.log("current-country-id:-"+countryId);
  useEffect(() => {
    fetch("https://ipapi.co/json/") //working
      // fetch('https://geolocation-db.com/json/') //not working
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        axios.get(`/sanctum/csrf-cookie`).then((response) => {
          axios
            .get(`/api/get-current-country/${responseJson.country_code}`)
            .then((response) => {
              // console.log("allow-1"+response);
              // console.log("allow-2"+response.data);

              //let cntyId = (response.data != null) ? response.data: 226;
              //alert(cntyId)

              //(response.data !=null) ? localStorage.setItem("country_id", response.data): localStorage.setItem("country_id", 226);
              //setCountryId(localStorage.getItem("country_id"));

              setCountryId(response?.data); // set
              //setCountryId(cntyId);
              localStorage.setItem("country_id", response.data); // set
              localStorage.setItem(
                "country_code",
                responseJson.country_code.toLowerCase()
              ); // set
              //localStorage.setItem("country_id", cntyId);
            });
        });
      });
  }, []);

  //(localStorage.getItem("country_id")!=null) ? localStorage.getItem("country_id") ://localStorage.setItem("country_id", 226);
  //console.log("country allow-"+localStorage.getItem("country_id"));
  //--------------- get country Id and state name on click---------
  let [statename, setStateName] = useState();

  const countryClick = (value) => () => {
    let country_id = value;
    setStateName("");
    setCountryId(country_id);
    // console.log(country_id);
    let elemen = document.getElementById("home-product");
    if (elemen) {
      elemen.scrollIntoView(100, 0);
    }
  };
  const elem = document.getElementById("home-product");
  const stateOnClick = (value) => () => {
    let state_name = value;
    setStateName(state_name);

    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/get-country-by-state/${state_name}`).then((response) => {
        setCountryId(response.data);
        // console.log(response.data);
        if (elem) {
          elem.scrollIntoView(100, 0);
        }
      });
    });
    // window.location.pathname = "#home-product";
  };

  //let country_id  = (countryId!='null') ? countryId : '226';
  //setCountryId(country_id);
  //-------------------------------------------------

  useEffect(() => {
    window.Echo.channel("ViewItemTriggered.room_u" + loggedInUserId).listen(
      "GetViewItemEvent",
      (response) => {
        // console.log(response);
        toast(
          response.message.user_name +
            " show your item. (" +
            response.message.item_name +
            ")"
        );
      }
    );
  }, []);

  const deleteClassStyle = {
    width: "20px",
    height: "20px",
    marginRight: "5px",
  };

  const styleSocialMedia = {
    textAlign: "center",
    paddingRight: "30px",
    fontSize: "15px",
    marginTop: "6px",
  };

  function clickLoginPopup() {
    localStorage.setItem("login_form", "add_item");
    setLoginPopupShow(true);
  }
  /*const ref = useRef(null);
	const [height, setHeight] = useState(0);
	useLayoutEffect(() => {
		setHeight(ref.current.offsetHeight);
	}, []);*/
  return (
    <>
      <Router>
        <ToastContainer />
        <GoToTop />
        <header id="site-header" className="main-header">
          <div className="header-top">
            <div className="container header_container">
              <div className="row mobile_content_between">
                <div className="col-md-2 main-logo">
                  <div className="bCatRight">
                    <div className="mobileMenu" style={{ display: "none" }}>
                      <MenuOutline onClick={onClickMenu} />
                    </div>
                    <div
                      className="mainCat"
                      style={{ display: isOpen ? "inline" : "none" }}
                    >
                      {localStorage.getItem("loggedin_user_id") === null ? (
                        <></>
                      ) : (
                        <NavLink
                          to="/add-item"
                          userContent={userContent}
                          state={{ userContent: userContent }}
                        >
                          <button className="btn mySellBtn mySellMobile">
                            <Camera color="#ffffff" />
                            Sell
                          </button>
                        </NavLink>
                      )}
                      <div className="form-group mySelect stateSelect">
                        <select
                          className="form-control"
                          id="country_id"
                          name="country_id"
                          value={countryId}
                          onChange={(e) => handleCountryChange(e)}
                        >
                          <option value="">-select_country-</option>
                          {countryName.map((val) => {
                            return (
                              <>
                                <option value={val.id}>
                                  {val.country_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <TopSearch countryId={countryId} />
                      <div className="form-group language-box">
                        <Tophead
                          language={language}
                          handleSetLanguage={(language) => {
                            setLanguage(language);
                            storeLanguageInLocalStorage(language);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Link to="/">
                    <img
                      src={
                        process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`
                      }
                      title="SnapSells"
                      alt="SnapSells"
                    />
                  </Link>
                </div>
                <div className="col-md-10 hRight white_icon_for_mobile">
                  <div className="myInput">
                    <div className="form-group mySelect stateSelect cImg">
                      <select
                        className="form-control"
                        id="country_id"
                        name="country_id"
                        value={countryId}
                        onChange={(e) => handleCountryChange(e)}
                      >
                        <option value="">-select_country-</option>
                        {countryName.map((val) => {
                          return (
                            <>
                              <option value={val.id}>{val.country_name}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <TopSearch countryId={countryId} />
                    <div className="form-group language-box">
                      <Tophead
                        language={language}
                        handleSetLanguage={(language) => {
                          setLanguage(language);
                          storeLanguageInLocalStorage(language);
                        }}
                      />
                    </div>
                    <Dropdown
                      className="mydropdown bell_icon_mobile"
                      onClick={show_notifi(`${loggedInUserId}`)}
                      style={{ display: "none" }}
                    >
                      <Dropdown.Toggle>
                        <Notifications
                          color="#817f80"
                          height="20px"
                          width="20px"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {getNotification.length ? (
                          <div>
                            <div className="notiClear">
                              <Dropdown.Item>
                                <TrashOutline style={deleteClassStyle} />
                                <span
                                  value={loggedInUserId}
                                  onClick={delete_all_notifi}
                                >
                                  All Clear
                                </span>
                              </Dropdown.Item>
                              <span className="notifyText">Notification</span>
                            </div>
                            {getNotification.map((val, k) => {
                              return (
                                <>
                                  {k < 3 ? (
                                    <div className="notiClear">
                                      <span className="mainText">
                                        <b>
                                          {val.sender_id == 0
                                            ? "Admin"
                                            : val.sendername}
                                        </b>
                                        &nbsp;{val.notification_title}
                                      </span>
                                      <small>{val.time_diff}</small>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                            <div className="viewAll">
                              <Dropdown.Item>
                                <Link to="/all-notification">+ View All</Link>
                              </Dropdown.Item>
                            </div>
                          </div>
                        ) : (
                          <div className="notiClear text-center">
                            <span className="notifyText">
                              No Notification Yet
                            </span>
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div
                      className="mydropdown chat_box_for_mobile"
                      style={{ display: "none" }}
                    >
                      <Link to="/chat">
                        <Chatbox color="#817f80" height="20px" width="20px" />
                      </Link>
                    </div>
                    {/*<Dropdown className="mydropdown mydDown">
										<Dropdown.Toggle><Chatbox /></Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
											<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
											<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>*/}
                    {localStorage.getItem("loggedin_user_id") === null ? (
                      <div className="logRegister header-logn-block">
                        <button
                          className="btn btn-info btn-lg lgn-poup"
                          onClick={() => setLoginPopupShow(true)}
                        >
                          {languageContent.login}
                        </button>
                        <button
                          className="btn btn-info btn-lg lgn-registr"
                          onClick={() => setRegisterShow(true)}
                        >
                          <span>{languageContent.register}</span>
                        </button>

                        <button
                          className="btn btn-warning mySellBtn mySellLogOutBtn"
                          onClick={(e) => clickLoginPopup(e)}
                        >
                          <Camera color="#ffffff" /> <span>Snap Listing</span>
                        </button>

                        <Loginmodal
                          language={language}
                          countryId={countryId}
                          languageContent={languageContent}
                          chgUserContent={(userContent) =>
                            setUserContent(userContent)
                          }
                          loginPopupShow={loginPopupShow}
                          registerShow={registerShow}
                          onLoginPopupCloseModal={(loginPopupShow) =>
                            setLoginPopupShow(false)
                          }
                          onRegisterPopupCloseModal={(registerShow) =>
                            setRegisterShow(false)
                          }
                        />
                      </div>
                    ) : (
                      <div className="userLogout">
                        <Dropdown className="myUser">
                          <Dropdown.Toggle>
                            <label>
                              {userContent?.is_verified == 1 &&
                              userContent.profile_picture ? (
                                // <img
                                //   src={userContent.profile_picture}
                                //   alt="Avatar"
                                //   height="30"
                                //   width="30"
                                //   className="personActive"
                                // />
                                <Badge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  badgeContent={
                                    <VerifiedIcon
                                    />
                                  }
                                >
                                  <Avatar
                                    alt="Avatar"
                                    src={userContent.profile_picture}
                                  />
                                </Badge>
                              ) : (
                                
                                <img
                                  src={userContent.profile_picture}
                                  alt="Avatar"
                                  height="30"
                                  width="30"
                                  className="personActive"
                                />
                              )}
                              <span>{userContent.name}</span>
                            </label>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <NavLink to="/my-profile">My Profile</NavLink>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <NavLink to="/notification">Notification</NavLink>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <NavLink to="/chat">Message</NavLink>
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="javascript:void(0)"
                              onClick={handleLogout}
                            >
                              {languageContent.logout}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <NavLink
                          to="/add-item"
                          userContent={userContent}
                          state={{ userContent: userContent }}
                        >
                          <button className="btn btn-warning mySellBtn mySellLogOutBtn">
                            <span>
                              <Camera color="#ffffff" /> Snap Listing
                            </span>
                          </button>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-bottom">
            <div className="container p-0">
              <div className="row header-bottom">
                <div className="col-md-12 col-12 browseCat">
                  <nav className="content">
                    <ul className="snapsells-menu">
                      <li className="mega-drop-down">
                        <NavLink to="#">Browse by categories</NavLink>
                        <div className="animated fadeIn mega-menu">
                          <div className="mega-menu-wrap">
                            <div className="container">
                              {/* <div className="row"> */}
                              {/* {Object.keys(categoryBrowseDetails).map((k) => {
                                  return ( */}
                              <>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="center"
                                  alignItems="flex-start"
                                >
                                  {rows.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                      <Grid
                                        container
                                        item
                                        // spacing={2}
                                        // justifyContent="center"
                                        // alignItems="flex-start"
                                      >
                                        {row.map((val, columnIndex) => (
                                          <Grid
                                            item
                                            xs={12 / 5}
                                            key={columnIndex}
                                          >
                                            <List sx={{ p: 0 }}>
                                              <ListItem sx={{ p: 0 }}>
                                                <ArrowRightIcon
                                                  style={{ marginRight: "8px" }}
                                                />
                                                <Link
                                                  to={`/category/${val.slug}`}
                                                >
                                                  <Typography>
                                                    {val.categoryname}
                                                  </Typography>
                                                </Link>
                                              </ListItem>
                                            </List>
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {rowIndex !== rows.length - 1 && (
                                        <Divider
                                          orientation="horizontal"
                                          flexItem
                                          sx={{ my: 2 }}
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                                {/* <ul>
                                        {menuData?.map(
                                          (val, j) => {
                                            return (
                                              <li
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <ArrowRightIcon
                                                  style={{ marginRight: "8px", marginTop:"8px"}}
                                                />
                                                <Link
                                                  to={`/category/${val.slug}`}
                                                >
                                                  {val.categoryname}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul> */}
                              </>
                              {/* );
                                })} */}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <NavLink
                          to="#"
                          className="toggle-menu-snapsells d-none"
                        >
                          |||
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-md-12 col-12 browseCatRight">
                  <ul className="main-menu-category">
                    {categoriesShuffled.map((val, j) => {
                      return (
                        <>
                          {j <= 4 ? (
                            <li>
                              <NavLink to={"/category/" + `${val.catslug}`}>
                                {val.categoryname}
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="App">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                  countryId={countryId}
                  chgCountryId={(countryId) => setCountryId(countryId)}
                  statename={statename}
                />
              }
            />

            <Route
              exact
              path="/user-dashboard"
              element={
                <Userdashboard
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              exact
              path="/my-profile"
              element={
                <Myprofile
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route path="/:slug" element={<Cms language={language} />} />
            <Route path="/test" element={<Test language={language} />} />
            <Route
              path="/resetpassword/:email"
              element={
                <Resetpassword
                  language={language}
                  languageContent={languageContent}
                />
              }
            />
            <Route
              path="/verify-user"
              element={
                <Verifyuser
                  language={language}
                  languageContent={languageContent}
                />
              }
            />
            <Route path="/appliances" element={<Appliances />} />
            <Route path="/category-list" element={<Categorylist />} />
            <Route path="/category-details" element={<Categorydetails />} />

            <Route
              path="/contact-us"
              element={
                <ContactUs
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            {/*<Route path="/about-us" element={<AboutUs language={language} languageContent={languageContent} userContent={userContent} chgUserContent={userContent => setUserContent(userContent)}/>}/>*/}

            <Route
              path="/add-item"
              element={
                <Additem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                  countryId={countryId}
                  chgCountryId={(countryId) => setCountryId(countryId)}
                />
              }
            />

            <Route
              path="/add-bulletin"
              element={
                <Addbulletin
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                  countryId={countryId}
                  chgCountryId={(countryId) => setCountryId(countryId)}
                />
              }
            />

            <Route
              path="/edit-item/:id"
              element={
                <Edititem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/edit-bulletin/:id"
              element={
                <Editbulletin
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/notification"
              element={
                <Notification
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            {/* <Route path="/sponsored-plans" element={<Sponsoredplans language={language} languageContent={languageContent} userContent={userContent} chgUserContent={userContent => setUserContent(userContent)}/>} /> */}

            <Route
              path="/customer-supports"
              element={
                <Customersupports
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/review"
              element={
                <Review
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/reviewitem/:id"
              element={
                <Reviewitem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/block-users"
              element={
                <BlockUsers
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/payment-details"
              element={
                <PaymentDetails
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/emergency-contact"
              element={
                <Emergencycontact
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/favourite-item"
              element={
                <Favouriteitem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/like-item"
              element={
                <Likeitem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/report-item"
              element={
                <Reportitem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/arrange-meeting"
              element={
                <ArrangeMeeting
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/my-item"
              element={
                <Myitem
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/my-bulletin"
              element={
                <Mybulletin
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/bulletin"
              element={
                <Bulletin
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/my-item-sold"
              element={
                <Myitemsold
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/item/:slug"
              element={
                <Itemdetails
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                  countryId={countryId}
                  chgCountryId={(countryId) => setCountryId(countryId)}
                />
              }
            />

            <Route
              path="/user-details/:id"
              element={
                <Userdetails
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/itemdetailstest/:slug"
              element={
                <Itemdetailstest
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />
            <Route
              path="/category/:slug"
              element={
                <CategoryWiselist
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                  countryId={countryId}
                  chgCountryId={(countryId) => setCountryId(countryId)}
                />
              }
            />
            <Route
              path="/all-notification"
              element={
                <AllNotification
                  language={language}
                  languageContent={languageContent}
                  getNotification={getNotification}
                  userContent={userContent}
                />
              }
            />
            <Route
              exact
              path="/help"
              element={
                <Help
                  language={language}
                  languageContent={languageContent}
                  userContent={userContent}
                  chgUserContent={(userContent) => setUserContent(userContent)}
                />
              }
            />

            <Route
              path="/help/:oth_user_id/:item_id"
              element={<Help userContent={userContent} />}
            />

            <Route
              path="/chat/:oth_user_id/:item_id"
              element={<Chat userContent={userContent} />}
            />
            <Route path="/chat" element={<Chat userContent={userContent} />} />

            <Route
              path="/term-and-condition"
              element={<TermCondition userContent={userContent} />}
            />
          </Routes>
        </div>

        <footer id="site-footer" className="main-footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-6 col-12 first-ul">
                  <h3>Popular locations</h3>
                  <ul>
                    {countryState.map((val) => {
                      return (
                        <>
                          <li>
                            <Link
                              to="/"
                              onClick={stateOnClick(`${val}`)}
                              language={language}
                              languageContent={languageContent}
                              userContent={userContent}
                              chgUserContent={(userContent) =>
                                setUserContent(userContent)
                              }
                              countryId={countryId}
                              chgCountryId={(countryId) =>
                                setCountryId(countryId)
                              }
                              statename={statename}
                            >
                              {val}
                            </Link>
                          </li>
                        </>
                      );
                    })}
                    {/*<li><Link to="#">California</Link></li>
								 <NavLink to="/add-item" userContent={userContent} state={{userContent: userContent }}></NavLink>
                                 <li><Link to="#">Illinois</Link></li>
                                 <li><Link to="#">Texas</Link></li>
                                 <li><Link to="#">Arizona</Link></li>
                                 <li><Link to="#">Pennsylvania</Link></li>
                                 <li><Link to="#">New York</Link></li>
                                 <li><Link to="#">California</Link></li>
                                 <li><Link to="#">Illinois</Link></li>
                                 <li><Link to="#">Texas</Link></li>
                                 <li><Link to="#">Arizona</Link></li>
                                 <li><Link to="#">Pennsylvania</Link></li>
                                 <li><Link to="#">New York</Link></li>
                                 <li><Link to="#">California</Link></li>
                                 <li><Link to="#">Illinois</Link></li>
                                 <li><Link to="#">Texas</Link></li>
                                 <li><Link to="#">Arizona</Link></li>
								 <li><Link to="#">Pennsylvania</Link></li>*/}
                  </ul>
                </div>
                <div className="col-md-5 col-sm-6 col-12 second-ul">
                  <h3>Trending locations</h3>
                  <ul>
                    {countryNameFooter.map((val) => {
                      return (
                        <>
                          <li>
                            <Link
                              to="/"
                              value={val.id}
                              onClick={countryClick(`${val.id}`)}
                              language={language}
                              languageContent={languageContent}
                              userContent={userContent}
                              chgUserContent={(userContent) =>
                                setUserContent(userContent)
                              }
                              countryId={countryId}
                              chgCountryId={(countryId) =>
                                setCountryId(countryId)
                              }
                              statename={statename}
                            >
                              {val.country_name}
                            </Link>
                          </li>
                        </>
                      );
                    })}
                    {/*<li><Link to="#">California</Link></li>
                                 <li><Link to="#">Illinois</Link></li>
                                 <li><Link to="#">Texas</Link></li>
                                 <li><Link to="#">Arizona</Link></li>
                                 <li><Link to="#">Pennsylvania</Link></li>
                                 <li><Link to="#">New York</Link></li>
                                 <li><Link to="#">California</Link></li>
                                 <li><Link to="#">Illinois</Link></li>
                                 <li><Link to="#">Texas</Link></li>
                                 <li><Link to="#">Arizona</Link></li>
								<li><Link to="#">Pennsylvania</Link></li>*/}
                  </ul>
                </div>
                <div className="col-md-2 col-sm-6 col-12 third-ul">
                  {/*<h3>SnapSells</h3>*/}
                  <Link to="/">
                    <img
                      src={
                        process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`
                      }
                      title="SnapSells"
                      height="40px"
                      alt="SnapSells"
                    />
                  </Link>
                  <h3></h3>
                  <ul>
                    {/*<li><Link to="/about-us">{languageContent.about_us}</Link></li>*/}
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    {/*<li><Link to="/faq">Faqs</Link></li>*/}
                    <li>
                      <Link to="/help">Help</Link>
                    </li>
                    <li>
                      <Link to="#">Sitemap</Link>
                    </li>
                    <li>
                      <Link to="privacy-policy">
                        Legal & Privacy information
                      </Link>
                    </li>
                  </ul>
                </div>
                {/*<div className="col-md-2 col-sm-6 col-12 fourth-apps">
								<h3>Apps</h3>
								
								<div className="play_app">
								
								<button onClick={() => mobileVerifyShow(true)}><img src={process.env.REACT_APP_FRONT_URL + `images/google_store.png`} alt="Google Play Store" title="Google Play Store" /></button>
								<button onClick={() => mobileVerifyShow(true)}><img src={process.env.REACT_APP_FRONT_URL + `images/app_store.png`} alt="Apple Store" title="Apple Store" /></button>
								 <div style={{ fontSize:12 }}>App is coming soon</div>
								
								
								<div className="row">
									<div style= { styleSocialMedia }>
									<button><a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank"><img src={process.env.REACT_APP_FRONT_URL + `/images/facebook-16.png`}  style={{ width: 28, height: 28 }} /></a></button>
									&nbsp;&nbsp;<button><a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank"><img src={process.env.REACT_APP_FRONT_URL + `/images/instagrams.png`}  style={{ width: 28, height: 28}} /></a></button>
									</div>
								</div>
								
								
								</div>							
							</div>*/}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-12">
                  Copyright © SnapSells inc 2022. All Rights Reserved. 
                  {/* Developed
                  by{" "}
                  <a href="https://eappmaker.com/" target="_blank">
                    eappmaker.com
                  </a> */}
                </div>
                <div className="col-md-6 col-sm-6 col-12 text-end">
                  {/*Follow us: 
                              <ul>
                                 <li>
                                    <Link to="#"><LogoFacebook /></Link>
                                 </li>
                                 <li>
                                    <Link to="#"><LogoInstagram /></Link>
                                 </li>
                                 <li>
                                    <Link to="#"><LogoTwitter /></Link>
                                 </li>
                              </ul>
						   */}
                  <div style={styleSocialMedia}>
                    <a href={process.env.REACT_APP_TIKTOK_URL} target="_blank">
                      <img
                        src={
                          process.env.REACT_APP_FRONT_URL +
                          `/images/tiktokIcon.png`
                        }
                        style={{ width: 32, height: 35 }}
                      />
                    </a>
                    &nbsp;&nbsp;
                    <a
                      href={process.env.REACT_APP_FACEBOOK_URL}
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.REACT_APP_FRONT_URL +
                          `/images/facebook-16.png`
                        }
                        style={{ width: 28, height: 28 }}
                      />
                    </a>
                    &nbsp;&nbsp;
                    <a
                      href={process.env.REACT_APP_INSTAGRAM_URL}
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.REACT_APP_FRONT_URL +
                          `/images/instagrams.png`
                        }
                        style={{ width: 28, height: 28 }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={verifyAccount} onHide={() => verifyAccountShow(false)}>
            <Modal.Header closeButton className="modalClose"></Modal.Header>
            <Modal.Body className="alertBody">
              <div className="vAccount">
                <div className="iconAlert">
                  <AlertCircle />
                </div>
                <h6>Verify Your Account</h6>
                <p>
                  Your Registration has been done successfully but your account
                  is not verified, if you want to verify your account kindly pay
                  $1.So we will verify your account successfully.
                </p>
                <button className="btn btnSkip">Skip</button>
                <button className="btn btnVerify">
                  Verify Now <ArrowForward />
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={mobileVerify} onHide={() => mobileVerifyShow(false)}>
            <Modal.Header closeButton className="modalClose"></Modal.Header>
            <Modal.Body className="alertBody">
              <div className="vAccount vDetails">
                <div className="iconAlert mVerify">
                  <PhonePortraitSharp />
                </div>
                <h6>Mobile Number Verification</h6>
                <p>
                  We have send you a verification code on your mobile number.
                </p>
                <p className="mobileNumber">+44 7832 160133</p>
                <div className="enterCode">
                  <h5>Enter 4 Digit Code</h5>
                  <div className="iCode">
                    <input type="text" className="form-control" />
                    <input type="text" className="form-control" />
                    <input type="text" className="form-control" />
                    <input type="text" className="form-control" />
                  </div>
                  <div className="rCode">
                    Did't receive the code?<Link to="#">Resend Code</Link>
                  </div>
                </div>
                <button className="btn btnVerify">
                  Submit <ArrowForward />
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </footer>
      </Router>
    </>
  );
}
function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}
export default App;
